import React from "react"
import { graphql, Link } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

// import required modules
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import { GatsbyImage } from "gatsby-plugin-image"

// install Swiper modules
SwiperCore.use([Navigation])

const IndexSlider = props => {
  console.log(props.slider_array)
  return (
    <div className="">
      <>
        <Fade bottom cascade>
          <Swiper
            slidesPerView={3}
            speed={1000}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            spaceBetween={0}
            loop={true}
            pagination={{
              el: props.gif
                ? ".my-custom-pagination-div-2"
                : ".my-custom-pagination-div",
              clickable: true,
              renderBullet: (index, className) => {
                return '<span class="' + className + '"> </span>'
              },
            }}
            navigation={{
              nextEl: ".swiper-button-next-square",
              prevEl: ".swiper-button-prev-square",
            }}
            breakpoints={{
              1536: {
                slidesPerView: 3,
              },
              1280: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 2,
              },
              0: {
                slidesPerView: 1,
              },
            }}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
          >
            {props.slider_array.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="relative">
                    {props.gif ? (
                      <>
                        <div
                          className="py-52 bg-cover"
                          style={{ backgroundImage: `url(${item.image})` }}
                        ></div>
                      </>
                    ) : props.hover ? (
                      <div>
                        <BackgroundImage {...item.image} className="py-52">
                          <div className="hover:bg-black/60 duration-200 group h-full flex items-center justify-center">
                            <Zoom duration={200}>
                              <Link to={item.link}>
                                <div className="py-2 px-3 hidden group-hover:block bg-white text-dusk uppercase hover:bg-dusk hover:text-white cursor-pointer duration-200">
                                  learn more
                                </div>
                              </Link>
                            </Zoom>
                          </div>
                        </BackgroundImage>
                      </div>
                    ) : (
                      <div>
                        <BackgroundImage
                          {...item.image}
                          className="py-52"
                        ></BackgroundImage>
                      </div>
                    )}

                    <div className="pr-10">
                      <div className="mt-10 text-mint text-2xl lg:text-left text-center ">
                        {item.title}
                      </div>
                      <div className="mt-6 text-white text-base lg:text-left text-center ">
                        {item.content}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </Fade>
      </>
    </div>
  )
}

export default IndexSlider
