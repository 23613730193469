// Developed by Explorelogy
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import MainSlider from "../components/common/main-slider"
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline"

import textile_white from "../images/icons/textile_white.svg"
import intergrate_white from "../images/icons/intergrate_white.svg"
import advance_white from "../images/icons/advance_white.svg"

import textile_mint from "../images/icons/textile_mint.svg"
import intergrate_mint from "../images/icons/intergrate_mint.svg"
import advance_mint from "../images/icons/advance_mint.svg"
import IndexSlider from "../components/common/index-slider"

const Platform = ({ data }) => {
  const images = {
    dusk_texure: convertToBgImage(getImage(data.dusk_texure)),
    led: convertToBgImage(getImage(data.led)),
    grid1: convertToBgImage(getImage(data.grid1)),
    grid2: convertToBgImage(getImage(data.grid2)),
    mint_texture: convertToBgImage(getImage(data.mint_texture)),
    slider_1: convertToBgImage(getImage(data.slider_1)),
    slider_2: convertToBgImage(getImage(data.slider_2)),
    slider_3: convertToBgImage(getImage(data.slider_3)),

    //intergrated carousel images

    visiblity_img: convertToBgImage(getImage(data.inter_img1)),
    data_img: convertToBgImage(getImage(data.inter_img2)),
    feedback_img: convertToBgImage(getImage(data.inter_img3)),
    flexible_battery_img: convertToBgImage(getImage(data.inter_img4)),
    heating_img: convertToBgImage(getImage(data.inter_img5)),
    sensing_img: convertToBgImage(getImage(data.inter_img6)),
    textile_circuitry_img: convertToBgImage(getImage(data.inter_img7)),
    touch_textile_img: convertToBgImage(getImage(data.inter_img8)),

    //advance material images

    accoustic_img: convertToBgImage(getImage(data.advance_img1)),
    bio_mimikry_img: convertToBgImage(getImage(data.advance_img2)),
    memory_foam_img: convertToBgImage(getImage(data.advance_img3)),
    pu_foam_img: convertToBgImage(getImage(data.advance_img4)),
    silicon_print_img: convertToBgImage(getImage(data.advance_img5)),
    silicon_img: convertToBgImage(getImage(data.advance_img6)),

    // textile engineering images

    engineered_knit_img: convertToBgImage(getImage(data.textile_img1)),
    inj_mold_img: convertToBgImage(getImage(data.textile_img2)),
    narrow_width_img: convertToBgImage(getImage(data.textile_img3)),

    //carousel main category images

    advance_material_img: convertToBgImage(getImage(data.main_carousel1)),
    intergrated_img: convertToBgImage(getImage(data.main_carousel2)),
    textile_img: convertToBgImage(getImage(data.main_carousel3)),

    //platform
    AdvancedMaterial: convertToBgImage(getImage(data.AdvancedMaterial)),
    Integratedtech: convertToBgImage(getImage(data.Integratedtech)),
    Textileengineering: convertToBgImage(getImage(data.Textileengineering)),
  }

  const [activePlatform, setActivePlatform] = useState(0)

  const slider = [
    {
      title: "Engineered Knit",
      content:
        "Leveraging Flat, Circular, and Warp knitting techniques to create complex structures, designs, and modulus.",
      image: images.engineered_knit_img,
      link: "/textile-engineering",
    },
    {
      title: "Narrow Width Fabrics",
      content:
        "Elasticated fabrics and elastomeric yarns engineered to achieve stretch and durability, as well as support complex design and construction.",
      image: images.narrow_width_img,
      link: "/textile-engineering",
    },
    {
      title: "Injection Molding",
      content:
        "A revolutionary foam and padding technology for impact protection and the encasing of electronics.",
      image: images.inj_mold_img,
      link: "/textile-engineering",
    },
  ]

  const slider2 = [
    {
      title: "Visibility ",
      content:
        " Next-to-skin LED-based lighting that provides on-demand illumination ensuring visibility and safety, now with RGB capabilities.",
      image: images.visiblity_img,
      link: "/integrated-technologies",
    },
    {
      title: "Thermal Regulation",
      content:
        "Intelligent fabric-based thermal regulation, that can support a variety of functions ranging from microclimate regulation to pain management.",
      image: images.heating_img,
      link: "/integrated-technologies",
    },
    {
      title: "Sensing",
      content:
        "Seamlessly integrated biopotential and biomechanical sensors that allow for the collection of metrics ranging from heart rate to motion.",
      image: images.sensing_img,
      link: "/integrated-technologies",
    },
    {
      title: "Feedback",
      content:
        " Haptics, TENS, and EMS integrated into soft goods for tactile feedback, notification, and chronic pain management.",
      image: images.feedback_img,
      link: "/integrated-technologies",
    },
    {
      title: "Touch Textiles",
      content:
        " Pressure-sensitive textile constructions used to create interactive interfaces, replacing the need for traditional plastic components.",
      image: images.touch_textile_img,
      link: "/integrated-technologies",
    },
    {
      title: "Data Transfer",
      content:
        " Transfer of data and power across fabric substrates with limited flow and interference and low resistance, supporting over 4gbps and 40V at 5A.",
      image: images.data_img,
      link: "/integrated-technologies",
    },
    {
      title: "Flexible Battery",
      content:
        "Adaptable battery with 3.7v 250mAh (Gen 1) and 7.4v 1000mAh (Gen 2) ​ power source for a multitude of wearable uses. Flexible, washable, semi-solid electrolyte construction that serves as the industry’s solution to the growing design needs.",
      image: images.flexible_battery_img,
      link: "/integrated-technologies",
    },

    {
      title: "Textile Circuitry",
      content:
        " A collection of conductive pathways and inlays, interconnects and encapsulation to present technology in a seamless and invisible manner",
      image: images.textile_circuitry_img,
      link: "/integrated-technologies",
    },
  ]

  const slider3 = [
    {
      title: "Bio-mimicking material",
      content:
        "Thermo-responsive polymers and yarns, pine-cone biomimetic yarn for moisture management for improved comfort over extended wear times.",
      image: images.bio_mimikry_img,
      link: "/advanced-materials",
    },
    {
      title: "PU foam-based Injection Molding",
      content:
        "Replacing hard plastic components for flexibility, durability, and seamless integration of sensors",
      image: images.pu_foam_img,
      link: "/advanced-materials",
    },
    {
      title: "Acoustic dampening fabrics",
      content:
        "Certified Acoustic fabrics specifically designed for uninterrupted sound passthrough from materials to product.",
      image: images.accoustic_img,
      link: "/advanced-materials",
    },
    {
      title: "Memory Foam with PU Leather",
      content:
        "Internal and external fabric/leather lamination on foam provides superior comfort and flexibility. Memory foam built for high impact/low frequency.",
      image: images.memory_foam_img,
      link: "/advanced-materials",
    },

    {
      title: "Silicone Printing and Embellishments",
      content:
        "Patented Silicone Application Technology to enable targeted modification of fabric modulus using engineered functional prints for grip and comfort",
      image: images.silicon_print_img,
      link: "/advanced-materials",
    },
  ]

  const [active, setActive] = useState(0)

  const howItWorks = [
    {
      switch_title: "Textile Engineering",
      sub_title: "PASSIVE TECHNOLOGY",
      title: "Powering Soft Textile Integration",
      content:
        "Technologies centered around the creation of Knit Structures, foam injection, and complex fabrics. These technologies are used as a base for numerous life-enhancing products.",
      image: textile_white,
      image_active: textile_mint,
      cover: images.Textileengineering,
      link: "/textile-engineering",
    },
    {
      switch_title: "Integrated Technologies",
      sub_title: "Integrated Technologies",
      title: "Integrated Soft Textile Integration",
      content:
        "A collection of intelligent sensors, actuators, and next-generation powering solutions integrated seamlessly into textiles.",
      image: intergrate_white,
      image_active: intergrate_mint,
      cover: images.Integratedtech,
      link: "/integrated-technologies",
    },
    {
      switch_title: "Advanced Materials",
      sub_title: "Advanced Materials",
      title: "Advanced Soft Textile Integration",
      content:
        "Our innovations in materials engineering offer a range of novel capabilities allowing for a new generation of devices that are soft, stretchable and anatomically compliant",
      image: advance_white,
      image_active: advance_mint,
      cover: images.AdvancedMaterial,
      link: "/advanced-materials",
    },
  ]

  //   const platform = [
  //     {
  //       title: "Technology Integration",
  //       sub_title: "Technology Embedded",
  //       content: "+ Patented Reaction Injection Moulding (RIM) technology",
  //       content2:
  //         "+ Ability to mold Polyurethane foam, TPU Film, Fabric (Nylon/Polyester/Elastane blends)",
  //       //   content3:
  //       //     "",
  //       image: "../../images/brand/symbol-mint.png",
  //       cover: images.led,
  //     },
  //     {
  //       title: "Finishes",
  //       sub_title: "Technology Embedded",
  //       content:
  //         "Our visibility solution comes in two main integratable form factors:",
  //       content2:
  //         "Light Guides (Strips) – LEDs embedded in optical grade silicone strips, perfect for safety based visibility applications.",
  //       content3:
  //         "Point Source (Dots) – Discrete LEDs embedded directly into fabric which function well for both visibility and aesthetic product differentiation.",
  //       image: "../../images/brand/symbol-mint.png",
  //       cover: images.dusk_texure,
  //     },
  //   ]

  const platformSlider = [
    {
      title: "Textile Engineering",
      image: textile_white,
      image_active: textile_mint,
    },
    {
      title: "Integrated Technologies",
      image: intergrate_white,
      image_active: intergrate_mint,
    },
    {
      title: "Advanced Material",
      image: advance_white,
      image_active: advance_mint,
    },
  ]

  return (
    <Layout>
      <Seo title="Platform" />
      <section>
        <BackgroundImage
          {...images.dusk_texure}
          className="h-screen bg-dusk overflow-hidden"
        >
          <div className="container h-full mx-auto">
            <div className="flex h-full items-center">
              <Fade bottom cascade>
                <div className="flex flex-col w-full z-10 sm:mt-0 -mt-52">
                  <div className="sm:text-6xl text-4xl  sm:text-left text-center  mb-1 max-w-2xl text-white">
                    Integrating <div>the soft with</div> the smart
                  </div>
                  <div className=" sm:text-2xl text-xl sm:max-w-lg text-white font-light mt-4  sm:text-left text-center">
                    Multi-functional smart textiles{" "}
                    <div>that create new function and form</div>
                  </div>
                </div>
              </Fade>
              <Fade right>
                <div className=" absolute right-0 bottom-0">
                  <StaticImage
                    src="../images/platform/hero.png"
                    alt="Softmatter"
                    placeholder="blurred"
                    width={820}
                  />
                </div>
              </Fade>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="bg-chalk">
          <div className="container mx-auto py-20">
            <div className="flex flex-col items-center">
              <Fade bottom cascade>
                <div>
                  <div className="text-mint mb-4 uppercase text-center ">
                    PATENTED TECHNOLOGY PLATFORMS
                  </div>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Powering Soft Seamless Integration
                  </div>
                  {/* <div className="text-2xl font-light text-center max-w-5xl leading-snug text-dusk">
                    Our groundbreaking textile-integrated illumination
                    technology that is revolutionizing the future of apparel,
                    setting new standards for safety, visibility, and product
                    differentiation. The platform offers more than 450 feet of
                    visibility in low light conditions with over 8 hours of
                    continuous illumination.
                  </div> */}
                  <div className="text-2xl font-light text-center max-w-5xl leading-snug mt-4 text-dusk">
                    With technology incorporated at a yarn level, our smart
                    textiles are poised to fuel the impending wearable
                    technology revolution. Our solutions leverage a range of
                    technology platforms that are multifaceted, fully
                    customizable, and extensively tested.
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-dusk py-24 overflow-hidden relative">
          <div className="container mx-auto">
            <div className="mb-20">
              <Fade top>
                <div className="text-center text-2xl font-firs-thin text-white mb-4">
                  THE TECHNOLOGY
                </div>
              </Fade>
              <Fade bottom>
                <div className="text-center lg:text-6xl text-4xl text-white">
                  Platforms
                </div>
              </Fade>
            </div>
            <div>
              <div className="mt-14 mb-36">
                <div className="flex items-center justify-center ">
                  {howItWorks.map((item, index) => {
                    return (
                      <Fade bottom cascade key={index}>
                        <div>
                          <div className="flex flex-col justify-center items-center">
                            {active == index ? (
                              <>
                                <img
                                  src={item.image_active}
                                  alt="Softmatter"
                                  className="mb-5 cursor-pointer sm:w-16 w-10"
                                  onClick={() => setActive(index)}
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={item.image}
                                  alt="Softmatter"
                                  className="mb-5 cursor-pointer sm:w-16 w-10"
                                  onClick={() => setActive(index)}
                                />
                              </>
                            )}
                            <div
                              className={
                                active == index
                                  ? "font-firs-medium cursor-pointer text-mint text-xs sm:text-xl pb-2 text-center border-b-2 px-5 w-full sm:w-4/5 border-mint duration-200"
                                  : "font-firs-medium cursor-pointer text-white text-xs sm:text-xl pb-2 text-center border-b-2 border-transparent px-5 w-full sm:w-4/5 duration-200"
                              }
                              onClick={() => setActive(index)}
                            >
                              {item.switch_title}
                            </div>
                          </div>
                        </div>
                      </Fade>
                    )
                  })}
                </div>
                <div className="mt-20">
                  <Fade bottom>
                    <div className="flex lg:flex-row flex-col sm:text-left text-center">
                      <BackgroundImage
                        {...howItWorks[active].cover}
                        className=" w-full lg:w-2/5 lg:py-0 py-32 sm:py-60 bg-dusk"
                      ></BackgroundImage>
                      <div className=" w-full lg:w-3/5 bg-dusk pl-0 lg:pl-14 p-14 pr-10">
                        <div className="text-xl font-light text-mint uppercase">
                          {howItWorks[active].sub_title}
                        </div>
                        <div className="text-2xl font-light mt-2 text-white">
                          {howItWorks[active].title}
                        </div>
                        <div className="text-xl font-light mt-2 text-white">
                          {howItWorks[active].content}
                        </div>
                        <div className="flex justify-center sm:justify-start">
                          <Link to={howItWorks[active].link}>
                            <button className="bg-mint py-3 px-6 mt-5 hover:bg-mint/50 duration-200 border border-mint hover:text-white  text-dusk uppercase">
                              learn more
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
            <div className="mb-20 text-xl sm:text-left text-center">
              <div className="text-mint ">EXPLORE PLATFORMS</div>
              <div className="text-4xl mt-2 my-4 text-mint ">
                Technology Woven In
              </div>
              <div className="text-white max-w-4xl">
                We believe that a future of smart textiles is inevitable, and
                the effective application of our technologies will result in the
                creation of products that change the way humanity lives and
                behaves.
              </div>
            </div>
            <div className="flex lg:flex-row flex-col lg:mb-44 ">
              <div className="w-full lg:w-4/12">
                <div className="flex gap-5 lg:gap-10 lg:flex-col justify-center flex-row">
                  {platformSlider.map((item, index) => {
                    return (
                      <Fade bottom cascade key={index}>
                        <div className=" text-white mb-14">
                          <div className="uppercase text-xl lg:block flex justify-center font-light mb-4">
                            {activePlatform == index ? (
                              <>
                                <img
                                  src={item.image_active}
                                  alt="Softmatter"
                                  className="cursor-pointer sm:w-16 w-10"
                                  onClick={() => setActivePlatform(index)}
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={item.image}
                                  alt="Softmatter"
                                  className="cursor-pointer sm:w-16 w-10"
                                  onClick={() => setActivePlatform(index)}
                                />
                              </>
                            )}
                          </div>
                          <div
                            className={
                              activePlatform == index
                                ? "mt-6  text-xs sm:text-xl text max-w-sm text-mint duration-100  lg:text-left text-center"
                                : "mt-6  text-xs sm:text-xl text max-w-sm duration-100 lg:text-left text-center"
                            }
                          >
                            <span
                              className={
                                activePlatform == index
                                  ? "pb-2 border-b-0 lg:border-b-2 border-mint duration-100 cursor-pointer"
                                  : "pb-2 duration-100 cursor-pointer hover:border-b-2 border-white"
                              }
                              onClick={() => setActivePlatform(index)}
                            >
                              {item.title}
                            </span>
                          </div>
                        </div>
                      </Fade>
                    )
                  })}
                </div>
              </div>
              <div className="w-full ">
                <div className="text-white lg:absolute lg:w-7/12 lg:mb-0 lg:pb-24 lg:right-0 w-full">
                  {activePlatform == 0 && (
                    <>
                      <IndexSlider slider_array={slider} hover />
                    </>
                  )}
                  {activePlatform == 1 && (
                    <>
                      <IndexSlider slider_array={slider2} hover />
                    </>
                  )}
                  {activePlatform == 2 && (
                    <>
                      <IndexSlider slider_array={slider3} hover />
                    </>
                  )}

                  <div className="flex items-center mt-24 justify-center">
                    <div className="text-white  my-custom-pagination-div"></div>
                    <div className="flex justify-center mr-20">
                      <div className="swiper-button-prev-square py-3 px-4 cursor-pointer flex items-center justify-center bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronLeftIcon width={20} />
                      </div>
                      <div className="swiper-button-next-square py-3 px-4 cursor-pointer bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronRightIcon width={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-chalk">
          <div className="">
            {/* <Fade bottom cascade>
              <div className="text-white">
                <div className="text-center text-2xl font-firs-thin  mb-4">
                  Our Padding
                </div>
                <div className="text-center text-6xl mb-4">Applications</div>
                <div className="text-2xl mt-2 text-center max-w-4xl mx-auto">
                  Workplace safety, impact protection for the elderly and
                  athletes, and encased sensing solutions for biopotential and
                  biometric monitoring.
                </div>
              </div>
            </Fade> */}
            <div className="overflow-hidden">
              <div className="flex lg:flex-row flex-col">
                <div className="w-full lg:w-1/2">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.grid1}
                        className="py-44 sm:py-72 bg-gray-400"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-dusk lg:pr-10 sm:text-left text-center">
                      <div className="text-xl font-light mb-3 leading-tight">
                        WHAT’S NEXT?
                      </div>
                      <div className="text-4xl font-light mb-6 leading-tight">
                        Technologies in
                        <div>Development</div>
                      </div>
                      <div className="text-2xl font-light mb-3 font-firs-medium leading-tight">
                        Active Cooling
                      </div>
                      <div className="text-xl mb-6 max-w-xl">
                        We leverage the power of a thermoelectric cooling system
                        to support health and wellness pain management
                        solutions.
                      </div>
                      <div className="text-2xl font-light font-firs-medium mb-3 leading-tight">
                        NFC and RFID
                      </div>
                      <div className="text-xl max-w-xl">
                        Lifecycle Traceability
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-mint bg-mint text-white mt-6 hover:bg-mint/20 hover:text-mint cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="w-full block lg:hidden">
                  <Fade right>
                    <div className="w-full ">
                      <BackgroundImage
                        {...images.grid2}
                        className="py-44 sm:py-60"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-dusk lg:pr-10 sm:text-left text-center">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Configuring Product
                        <div>Applications</div>
                      </div>
                      <div className="text-xl max-w-xl">
                        Capabilitles across the entire product
                        <div>configuration application</div>
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-mint bg-mint text-white mt-6 hover:bg-mint/20 hover:text-mint cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="w-full lg:w-1/2 hidden lg:block">
                  <Fade right>
                    <div className="w-full ">
                      <BackgroundImage
                        {...images.grid2}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="flex lg:flex-row flex-col">
          <div className="w-full lg:w-1/2">
            <BackgroundImage
              {...images.mint_texture}
              className="h-full bg-cover lg:py-0 py-40"
            >
              <Zoom>
                <div className="flex h-full items-center justify-center">
                  <StaticImage
                    src="../images/brand/logo-symbol.png"
                    placeholder="blurred"
                    alt="Softmatter"
                    className="lg:w-auto w-20"
                    width={180}
                  />
                </div>
              </Zoom>
            </BackgroundImage>
          </div>
          <div className="w-full lg:w-1/2">
            <Fade bottom cascade>
              <div className="bg-chalk py-20 lg:py-56 text-dusk px-10">
                <div className="text-center  text-5xl sm:text-6xl mb-4">
                  Contact Us
                </div>
                <div className="text-xl mt-2 text-center max-w-xl mx-auto">
                  At Softmatter, our dynamic team has industry-leading expertise
                  in smart textiles, from design to manufacture. We help you
                  strategically enhance your offering so that it has a profound,
                  global impact.
                </div>
                <div className="text-2xl mt-5 text-center max-w-4xl mx-auto">
                  Let's get started.
                </div>
                <div className="flex justify-center mt-8">
                  <Link to="/contact">
                    <button className="px-8 py-4 bg-mint  hover:bg-mint/90 duration-200 text-white text-2xl">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query PlatformImages {
    dusk_texure: file(relativePath: { eq: "textures/dusk_texure.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    led: file(relativePath: { eq: "visibility/led.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid1: file(relativePath: { eq: "platform/grid1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid2: file(relativePath: { eq: "platform/grid2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    mint_texture: file(relativePath: { eq: "home/mint_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_1: file(relativePath: { eq: "home/slider/slider_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_2: file(relativePath: { eq: "home/slider/slider_2.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_3: file(relativePath: { eq: "home/slider/slider_3.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img1: file(
      relativePath: { eq: "platform-carousel/Integrated/Visibility.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img2: file(
      relativePath: { eq: "platform-carousel/Integrated/Data.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img3: file(
      relativePath: { eq: "platform-carousel/Integrated/Feedback.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img4: file(
      relativePath: { eq: "platform-carousel/Integrated/Flexible_Battery.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img5: file(
      relativePath: { eq: "platform-carousel/Integrated/Heating.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img6: file(
      relativePath: { eq: "platform-carousel/Integrated/Sensing.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img7: file(
      relativePath: { eq: "platform-carousel/Integrated/Textile_circuitry.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img8: file(
      relativePath: { eq: "platform-carousel/Integrated/Touch_textiles.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img1: file(
      relativePath: { eq: "platform-carousel/Advanced-Materials/Accoustic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img2: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Bio_Mimikry_Material.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img3: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Memory_Foam_with_PU_Leather.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img4: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/PU_foam_based_Injection_Molding.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img5: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Silicone_Printing.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img6: file(
      relativePath: { eq: "platform-carousel/Advanced-Materials/Silicone.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel1: file(
      relativePath: { eq: "Main_category_carousel/Advanced-Material.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel2: file(
      relativePath: { eq: "Main_category_carousel/Integrated.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel3: file(
      relativePath: { eq: "Main_category_carousel/Textile-Engineering.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    textile_img1: file(
      relativePath: {
        eq: "platform-carousel/Textile-Engineering/Engineered_Knit.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    textile_img2: file(
      relativePath: {
        eq: "platform-carousel/Textile-Engineering/Injection_Molding.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    textile_img3: file(
      relativePath: {
        eq: "platform-carousel/Textile-Engineering/Nawrrow_Width.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    AdvancedMaterial: file(
      relativePath: { eq: "platform/AdvancedMaterial.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Integratedtech: file(relativePath: { eq: "platform/Integratedtech.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Textileengineering: file(
      relativePath: { eq: "platform/Textileengineering.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`

export default Platform
